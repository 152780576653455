function openSidebar(sidebarName) {
  const sidebars = document.getElementsByClassName('sidebar');
  const sidebarButtons = document.getElementsByClassName('js-sidebar-button');

  sidebarButtons.forEach((sidebarButton) => {
    if (sidebarButton.getAttribute('data-sidebar-toggle') === sidebarName) {
      // add active classes
      sidebarButton.classList.add('text-virtual-green');
      sidebarButton.classList.add('text-shadow-green');
    } else {
      // remove active classes
      sidebarButton.classList.remove('text-virtual-green');
      sidebarButton.classList.remove('text-shadow-green');
    }
  });

  sidebars.forEach((sidebar) => {
    if (sidebar.getAttribute('data-sidebar') === sidebarName) {
      // show sidebar
      sidebar.classList.remove('hidden');
    } else {
      // hide other sidebars
      sidebar.classList.add('hidden');
    }
  });
}

function closeSidebars() {
  const sidebars = document.getElementsByClassName('sidebar');
  const sidebarButtons = document.getElementsByClassName('js-sidebar-button');

  sidebarButtons.forEach((sidebarButton) => {
    // remove active classes
    sidebarButton.classList.remove('text-virtual-green');
    sidebarButton.classList.remove('text-shadow-green');
  });

  sidebars.forEach((sidebar) => {
    // hide sidebars
    sidebar.classList.add('hidden');
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const sidebarButtons = document.getElementsByClassName('js-sidebar-button');
  const sidebarCloseButtons = document.getElementsByClassName('js-sidebar-close');

  if (sidebarButtons.length) {
    console.log('ddd', sidebarButtons)
    sidebarButtons.forEach((sidebarButton) => {
      sidebarButton.addEventListener('click', () => {
        const sidebarName = sidebarButton.getAttribute('data-sidebar-toggle');
        const sidebar = document.getElementById(`sidebar-${sidebarName}`);

        if (sidebar.classList.contains('hidden')) {
          openSidebar(sidebarName);
        } else {
          closeSidebars();
        }
      });
    });
  }

  if (sidebarCloseButtons.length) {
    sidebarCloseButtons.forEach((sidebarCloseButton) => {
      sidebarCloseButton.addEventListener('click', () => {
        closeSidebars();
      });
    });
  }
});
